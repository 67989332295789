import styled from 'styled-components';

export const Message = styled.div`
  pre {
    background-color: ${({ theme }) => theme.colors.neutral.palette800};
    padding: ${({ theme }) => theme.spacing.sm}px;
    border-radius: ${({ theme }) => theme.radii.sm}px;
    position: relative;
  }
  .title {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    svg {
      margin-right: 10px;
    }
  }
  .error-message {
    background-color: ${({ theme }) => theme.colors.neutral.palette700};
    padding: ${({ theme }) => theme.spacing.sm}px
      ${({ theme }) => theme.spacing.sm}px ${({ theme }) => theme.spacing.lg}px;
    border-radius: ${({ theme }) => theme.radii.sm}px;
    white-space: pre-wrap;

    & {
      max-height: 400px;
      overflow: auto;

      &:before {
        content: '';
        position: absolute;
        background: linear-gradient(
          0deg,
          ${({ theme }) => theme.colors.neutral.palette700} 40%,
          transparent
        );
        width: calc(100% - ${({ theme }) => theme.spacing.sm * 2}px);
        height: 40px;
        bottom: ${({ theme }) => theme.spacing.sm}px;
        left: ${({ theme }) => theme.spacing.sm}px;
      }
    }
  }
`;
