export const CURRENCY = '€';

/**
 * Timeout in [ms] to redirect to simulate a payment and then go to the confirmation page
 * @default 3000 (3 seconds)
 */
export const PAYMENT_TIMEOUT = 3000;

/**
 * Timeout in [ms] to consider as an idle session and then cancel it
 * @default 120000 (2 minutes)
 */
export const IDLE_TIMEOUT = 120000; /**

 * Timeout in [ms] to consider as an idle Assisted Sales session and then cancel it
 * @default 120000 (2 minutes)
 */
export const IDLE_TIMEOUT_ASSISTED_SALES = 120000;

/**
 * Timeout in [ms] to redirect to home after rating
 * @default 3000 (3 seconds)
 */
export const FROM_CONFIRMATION_TO_HOME_TIMEOUT = 3000;

/**
 * Timeout in [ms] to redirect to home after rating on Assisted Sales
 * @default 3000 (10 seconds)
 */
export const FROM_CONFIRMATION_TO_HOME_TIMEOUT_ASSISTED_SALES = 10000;

/**
 * [mock-only] Delay for basket response - used to simulate basket processing.
 * @default 7000 (7 seconds)
 */
export const GET_BASKET_DELAY = 7000;

/**
 * Timeout for basket response
 * @default 120000 (2 minutes)
 */
export const GET_BASKET_TIMEOUT = 120000;

/**
 * Retries on fetch with basket error response
 * @default 0 (no retries, return an error)
 */
export const GET_BASKET_RETRY_COUNT = 0;

/**
 * Interval to re-fetch while the basket is NOT_READY
 * @default 1000 (1 second)
 */
export const GET_BASKET_INTERVAL = 1000;

/**
 * Interval to re-fetch while the basket is READY
 * @default 5000 (5 seconds)
 */
export const GET_BASKET_READY_INTERVAL = 2500;

// TODO: Create Custom Sensei UI theme
export const customTheme = {
  colors: {
    primary: {
      palette50: '#edf8f1',
      palette100: '#dbf1e2',
      palette200: '#b8e3c5',
      palette300: '#94d5a8',
      palette400: '#71c78b',
      palette500: '#4DB96E',
      palette600: '#3e9458',
      palette700: '#2e6f42',
      palette800: '#1f4a2c',
      palette900: '#0f2516',
    },
    neutral: {
      palette400: 'rgba(16, 18, 19, 0.4)',
      white: '#FFFFFF',
    },
  },
};
