import React from 'react';
import { render } from 'react-dom';
import App from '@/shared/components/app/app';
import { BrowserRouter as Router } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import './i18n';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

render(
  <React.StrictMode>
    <>
      <Router>
        <App />
      </Router>
    </>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorkerRegistration.register();
