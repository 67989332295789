import React from 'react';
import { FallbackProps } from 'react-error-boundary';
import { Icon, Text } from '@metaicg/sensei-ui';
import { Message } from './styles';

interface FallbackComponentProps extends Pick<FallbackProps, 'error'> {
  title?: string;
}

const FallbackComponent = ({
  title = 'Something went wrong!',
  error,
}: FallbackComponentProps) => (
  <Message role="alert">
    <pre>
      <Text as="p" color="neutral.palette100" size="md" className="title">
        <Icon name="bug-2-line" color="error" size="title" /> {title}
      </Text>
      <Text as="p" color="error" className="error-message">
        {error.stack || error.message}
      </Text>
    </pre>
  </Message>
);

export default FallbackComponent;
