import { createGlobalStyle } from 'styled-components';
import { customTheme } from '@/shared/constants';

export const GlobalStyles = createGlobalStyle`
  body * {
  -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  
  
  html, body {
    // TODO: Remove important after fix typography css reset
    overflow-y: auto !important;
    
    .sensei-ui-tooltip-content a {
      color: ${({ theme }) => theme.colors.neutral.palette100};
    }
  }
  html span {
    font-family: 'Poppins', sans-serif;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
  }
  
  html img {
    margin-bottom: initial;
  }

  // TODO: (custom scrollbar) move to Sensei UI as an utility style
  .custom-scroll {
    scrollbar-width: thin;
    scrollbar-color: ${customTheme.colors.neutral.white} transparent;
  }
  
  .custom-scroll::-webkit-scrollbar {
    width: 36px;
  }

  .custom-scroll::-webkit-scrollbar-track {
    background: transparent;
  }

  .custom-scroll::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.neutral.palette800};
    border-radius: 20px;
    border: 15px solid ${customTheme.colors.neutral.white};
  }
`;
