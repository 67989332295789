import React from 'react';
import Layout from '@/shared/components/layout';
import { LazyRoute } from '@/shared/utils/router';
import ROUTE_PATHS from '@/routes/route-paths';
import { RouteObject } from 'react-router-dom';

// TODO: Create private layout prop
const routes: RouteObject[] = [
  // PRIVATE ROUTES --------------------------------------------------------------------------------------------------
  {
    element: <Layout />,
    children: [
      // MAIN INDEX --------------------------------------------------------------------------------------------------
      {
        index: true,
        element: <LazyRoute path="domain/payment-terminal/pages/main" />,
      },
      {
        path: ROUTE_PATHS.paymentTerminal.root,
        element: <LazyRoute path="domain/payment-terminal/pages/main" />,
      },
      {
        path: ROUTE_PATHS.paymentTerminal.basket,
        element: <LazyRoute path="domain/payment-terminal/pages/basket" />,
      },
      {
        path: ROUTE_PATHS.paymentTerminal.confirmation,
        element: <LazyRoute path="domain/payment-terminal/pages/confirmation" />,
      },
      // ASSISTED SALES ------------------------------------------------------------------------------------------------
      {
        path: ROUTE_PATHS.assistedSales.root,
        element: <LazyRoute path="domain/assisted-sales/pages/main" />,
      },
      {
        path: ROUTE_PATHS.assistedSales.basket,
        element: <LazyRoute path="domain/assisted-sales/pages/products" />,
      },
      {
        path: ROUTE_PATHS.assistedSales.confirmation,
        element: <LazyRoute path="domain/assisted-sales/pages/confirmation" />,
      },
      {
        path: ROUTE_PATHS.settings,
        element: <LazyRoute path="domain/app/pages/404" />,
      },
    ],
  },

  // PUBLIC ROUTES -----------------------------------------------------------------------------------------------------
  {
    element: <Layout />,
    children: [
      // APP -----------------------------------------------------------------------------------------------------------
      {
        path: ROUTE_PATHS.login,
        element: <LazyRoute path="domain/app/pages/404" />,
      },
      {
        path: '*',
        element: <LazyRoute path="domain/app/pages/404" />,
      },
    ],
  },
];

export { ROUTE_PATHS };

export default routes;
