import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ns: ['assisted-sales', 'common', '404'],
    load: 'languageOnly',
    defaultNS: 'common',
    fallbackNS: 'common',
    fallbackLng: 'en',
    backend: {
      loadPath: process.env.PUBLIC_URL + '/locale/{{lng}}/{{ns}}.json',
    },
    detection: {
      order: ['querystring', 'cookie', 'navigator'],
      lookupQuerystring: 'lang',
      lookupCookie: 'lang',
      lookupLocalStorage: 'lang',
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,
      caches: ['cookie'],
      cookieMinutes: 1440,
    },
    react: {
      useSuspense: true,
      transEmptyNodeValue: '',
    },
  });

i18n.services.formatter?.add('lowercase', (value) => value.toLowerCase());

export default i18n;
