import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface LayoutWrapperProps {
  children: ReactNode;
}

const Container = styled.div`
  max-width: ${({ theme }) => theme.breakpoints.wide}px;
  height: 100vh;
  min-height: 800px;
  width: 100vw;
  padding: ${({ theme }) => theme.spacing.xl * 1.75}px;
  margin: 0 auto;
`;

const LayoutWrapper = ({ children }: LayoutWrapperProps) => <Container>{children}</Container>;

export default LayoutWrapper;
export { Container as LayoutWrapperStyles };
