const routePaths = {
  login: '/login',
  settings: '/settings',
  paymentTerminal: {
    root: '/',
    basket: '/payment-terminal/basket/:ids',
    confirmation: '/payment-terminal/confirmation',
  },
  assistedSales: {
    root: '/assisted-sales',
    basket: '/assisted-sales/products',
    confirmation: '/assisted-sales/confirmation',
  },
};

export default routePaths;
