import React, { ReactElement } from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import FallbackComponent from './fallback-component';

function ErrorBoundary({
  children,
  title,
}: {
  children: ReactElement<any, any>;
  title?: string;
}) {
  return (
    <ReactErrorBoundary
      FallbackComponent={({ error }) => (
        <FallbackComponent error={error} title={title} />
      )}
    >
      {children}
    </ReactErrorBoundary>
  );
}

export default ErrorBoundary;
