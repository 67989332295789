import React, { lazy } from 'react';

/**
 * Import a route component lazily
 * @param path
 // * @returns {LazyExoticComponent<ComponentType<any>>}
 */
const importLazyComponentRoute = (path: string) =>
  lazy(() => import(/* webpackChunkName: "[request]" */ `@/${path}`));

const LazyRoute: any = ({ path }: { path: string }) => {
  const Component = importLazyComponentRoute(path);
  return <Component />;
};

export { importLazyComponentRoute, LazyRoute };
