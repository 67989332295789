import React from 'react';
import { LayoutProps } from './layout.types';
import ErrorBoundary from '@/shared/components/error-boundary';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { Layout as UILayout, ThemeProvider } from '@metaicg/sensei-ui';
import { Container } from './styles';
import { withFormik } from 'formik';

const Layout = ({ children }: LayoutProps) => {
  const { t } = useTranslation();

  return (
    <ThemeProvider>
      <Container>
        <UILayout header={false}>
          <ErrorBoundary title={t('common:messages.error.generic')}>
            <>{children || <Outlet />}</>
          </ErrorBoundary>
        </UILayout>
      </Container>
    </ThemeProvider>
  );
};

export default withFormik({
  handleSubmit: () => {},
  displayName: 'TaxForm',
})(Layout);
