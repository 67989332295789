import styled, { keyframes } from 'styled-components';

const pageAnimation = keyframes`
  0% {
    opacity: 0;
    filter: blur(10);
  }
  100% {
    opacity: 1;
    filter: blur(0);
  }
`;
const pageAnimationSlideUp = keyframes`
  0% {
    top: 50px;
    opacity: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
`;

export const Container = styled.div`
  background: initial;
  > div > div {
    background: transparent;
    min-height: 700px;
  }

  .page-transition {
    animation: ${pageAnimation} 0.9s ease-in-out;
  }

  .page-transition-slide-up {
    position: relative;
    animation: ${pageAnimationSlideUp} 0.6s ease-in-out,
      ${pageAnimationSlideUp} 0.6s ease-in-out;
  }

  main {
    max-height: 100vh;
    padding: 0;
    overflow-y: auto;
  }
`;
